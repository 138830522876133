import React from "react"
import { Link } from "gatsby"
const PageError = () => {
  return (
    <div className="container page-404">
      <h1>404</h1>
      <h4>NOT FOUND</h4>
      <p>The page you are looking for not avaible!</p>
      <Link to="/">Go back to home</Link>
    </div>
  )
}

export default PageError
